import { either } from 'fp-ts';

import { CompleteInfo, Invitation } from './app/state';

export type InvitationResponse =
  | {
      _tag: 'found';
      invitation: Invitation;
    }
  | {
      _tag: 'notFound';
    }
  | {
      _tag: 'done';
      previousAnswer: CompleteInfo;
    };
type ApiClient = {
  getInvitation(args: {
    code: string | undefined;
  }): Promise<InvitationResponse>;
  submitResponse(data: CompleteInfo): Promise<either.Either<'failed', 'ok'>>;
};

const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:3001';
const realApiClient: ApiClient = {
  getInvitation: async ({ code }) => {
    if (code == null) {
      return { _tag: 'notFound' };
    }

    const response = await fetch(`${API_URL}/invitation?code=${code}`, {
      method: 'GET',
    });
    // eslint-disable-next-line no-console
    console.log(response);

    if (response.status === 404) {
      return { _tag: 'notFound' };
    }

    if (!response.ok) {
      throw new Error('failed to get code');
    }

    const { invitation, previousAnswer } = await response.json();
    if (previousAnswer != null) {
      return { _tag: 'done', previousAnswer };
    }

    return { _tag: 'found', invitation };
  },

  submitResponse: async (data) => {
    try {
      const response = await fetch(`${API_URL}/answer`, {
        body: JSON.stringify({
          fullAnswer: data,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (!response.ok) {
        return either.left('failed');
      }

      return either.right('ok');
    } catch (_error) {
      return either.left('failed');
    }
  },
};

const mockApiClient: ApiClient = {
  getInvitation: async ({ code }): Promise<InvitationResponse> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    switch (code) {
      case 'SENJ':
        return {
          _tag: 'found',
          invitation: {
            code,
            fixedPersonen: [
              {
                familienaam: 'Van Genechten',
                voornaam: 'Sara',
              },
              {
                familienaam: 'Claes',
                voornaam: 'Jens',
              },
            ],
            invitedToCeremony: true,
            invitedToDansfeest: true,
            invitedToDinner: true,
            invitedToReceptie: true,
            maxAantalPersonen: 2,
            name: 'Familie Claes-Van Genechten',
          },
        };
      case 'PLUSONE':
        return {
          _tag: 'found',
          invitation: {
            code,
            fixedPersonen: [
              {
                familienaam: '???',
                voornaam: 'Samuel',
              },
            ],
            invitedToCeremony: true,
            invitedToDansfeest: false,
            invitedToDinner: false,
            invitedToReceptie: true,
            maxAantalPersonen: 2,
            name: 'Samuel + 1',
          },
        };
      case 'SEVEN':
        return {
          _tag: 'found',
          invitation: {
            code,
            fixedPersonen: [
              { familienaam: 'Dwerg', voornaam: 'Doc' },
              { familienaam: 'Dwerg', voornaam: 'Dopey' },
              { familienaam: 'Dwerg', voornaam: 'Bashful' },
              { familienaam: 'Dwerg', voornaam: 'Grumpy' },
              { familienaam: 'Dwerg', voornaam: 'Sneezy' },
              { familienaam: 'Dwerg', voornaam: 'Sleepy' },
              { familienaam: 'Dwerg', voornaam: 'Happy' },
            ],
            invitedToCeremony: true,
            invitedToDansfeest: true,
            invitedToDinner: false,
            invitedToReceptie: true,
            maxAantalPersonen: 7,
            name: 'Familie Met Zeven',
          },
        };
      case 'RECEP':
        return {
          _tag: 'found',
          invitation: {
            code,
            fixedPersonen: [
              {
                familienaam: '',
                voornaam: 'Gin',
              },
              {
                familienaam: '',
                voornaam: 'Tonic',
              },
            ],
            invitedToCeremony: false,
            invitedToDansfeest: false,
            invitedToDinner: false,
            invitedToReceptie: true,
            maxAantalPersonen: 3,
            name: 'Familie Receptie-only',
          },
        };
      case 'DONE': {
        return {
          _tag: 'done',
          previousAnswer: {
            code,
            familyInfo: {
              aantalPersonen: 1,
              adres: {
                gemeente: 'Brussel',
                huisnummer: '2',
                postcode: '1000',
                straatnaam: 'Stationstraat',
              },
            },
            invitation: {
              code,
              fixedPersonen: [],
              invitedToCeremony: true,
              invitedToDansfeest: false,
              invitedToDinner: false,
              invitedToReceptie: false,
              maxAantalPersonen: 4,
              name: 'Familie met reservatie',
            },
            personInfo: [
              {
                aanwezigCeremonie: false,
                aanwezigDans: false,
                aanwezigEten: false,
                aanwezigReceptie: false,
                dans: undefined,
                emailadres: 'me@example.com',
                eten: undefined,
                familienaam: 'Example',
                fixedPersonIndex: null,
                gsmNummer: '',
                voornaam: 'Me',
              },
            ],
          },
        };
      }
      default:
        return {
          _tag: 'notFound',
        };
    }
  },

  submitResponse: async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (data.personInfo[0]?.voornaam === 'Fail') {
        return either.left('failed');
      }
      return either.right('ok');
    } catch (_error) {
      return either.left('failed');
    }
  },
};

export const apiClient = process.env.REACT_APP_USE_MOCK_CLIENT
  ? mockApiClient
  : realApiClient;
